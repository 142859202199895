<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="151.031"
    height="66.326"
    viewBox="0 0 151.031 66.326"
  ><g class="a"><rect
    class="d"
    width="138.379"
    height="48.84"
    rx="24.42"
  /><rect
    class="e"
    x="1.5"
    y="1.5"
    width="135.379"
    height="45.84"
    rx="22.92"
  /></g><text
    class="b"
    transform="translate(31.676 28.35)"
  ><tspan
    x="0"
    y="0"
  >CONNECT NOW</tspan></text><g transform="translate(105.821 21.115)"><g transform="translate(16.103 16.101)"><path
    class="c"
    d="M203.59,211.065l-7.728-7.727-3.746,3.746a1.323,1.323,0,0,1-2.192-.517l-7.493-22.478a1.324,1.324,0,0,1,1.675-1.675l22.478,7.493a1.323,1.323,0,0,1,.517,2.192l-3.746,3.746,7.727,7.728a1.324,1.324,0,0,1,0,1.873l-5.619,5.619A1.324,1.324,0,0,1,203.59,211.065Z"
    transform="translate(-182.362 -182.344)"
  /></g><g transform="translate(15.894)"><path
    class="c"
    d="M181.325,10.6A1.324,1.324,0,0,1,180,9.272V1.325a1.325,1.325,0,0,1,2.649,0V9.272A1.324,1.324,0,0,1,181.325,10.6Z"
    transform="translate(-180 0)"
  /></g><g transform="translate(4.655 4.655)"><path
    class="c"
    d="M58.728,60.6l-5.62-5.62a1.324,1.324,0,1,1,1.873-1.873l5.62,5.62A1.324,1.324,0,0,1,58.728,60.6Z"
    transform="translate(-52.72 -52.72)"
  /></g><g transform="translate(4.655 21.513)"><path
    class="c"
    d="M53.108,251.515a1.324,1.324,0,0,1,0-1.873l5.62-5.62a1.324,1.324,0,1,1,1.873,1.873l-5.62,5.62A1.324,1.324,0,0,1,53.108,251.515Z"
    transform="translate(-52.72 -243.634)"
  /></g><g transform="translate(21.513 4.655)"><path
    class="c"
    d="M244.021,60.6a1.324,1.324,0,0,1,0-1.873l5.62-5.62a1.324,1.324,0,1,1,1.873,1.873l-5.62,5.62A1.324,1.324,0,0,1,244.021,60.6Z"
    transform="translate(-243.633 -52.721)"
  /></g><g transform="translate(0 15.894)"><path
    class="c"
    d="M9.272,182.649H1.325a1.325,1.325,0,1,1,0-2.649H9.272a1.325,1.325,0,1,1,0,2.649Z"
    transform="translate(0 -180)"
  /></g></g></svg>
</template>

<script>
export default {
  name: 'SignUp'
}
</script>

<style scoped>
.a {
  fill: #fff;
  stroke: #999999;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}
.c {
  fill: #999999;
}
.b {
  font-size: 10px;
  font-family: MuseoSans-900, Museo Sans;
  font-weight: 900;
  letter-spacing: 0.056em;
  color: #999999;
}
.d {
  stroke: none;
}
.e {
  fill: none;
}
</style>
