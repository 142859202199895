<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64.439"
    height="64.449"
    viewBox="0 0 64.439 64.449"
  ><g transform="translate(0.005 0)"><g transform="translate(0)"><path
    class="a"
    d="M63.988.524a1.806,1.806,0,0,0-1.51-.507L42.422,2.845a1.766,1.766,0,0,0-1.015.507l-13.79,13.79H1.865A1.788,1.788,0,0,0,.609,20.2L12.584,32.175l-1.1,1.1a1.612,1.612,0,0,0,0,2.525L28.708,53.018a1.714,1.714,0,0,0,2.537,0l1.1-1.1L44.325,63.89a1.633,1.633,0,0,0,1.954.393,1.806,1.806,0,0,0,1.091-1.662V36.882l13.79-13.79a1.766,1.766,0,0,0,.507-1.015L64.5,2.034A1.793,1.793,0,0,0,63.988.524Zm-57.8,20.2H24.04l-8.931,8.918Zm37.6,37.589L34.874,49.39l8.918-8.918ZM58.216,21,29.964,49.225l-14.69-14.69L43.513,6.309,60.626,3.9Z"
    transform="translate(-0.078 0)"
  /></g></g><g transform="translate(0 42.778)"><path
    class="a"
    d="M9.518,337.728a1.8,1.8,0,0,0-2.537,0L.561,344.147a1.784,1.784,0,0,0,0,2.524,1.873,1.873,0,0,0,2.537,0l6.432-6.419A1.773,1.773,0,0,0,9.518,337.728Z"
    transform="translate(-0.038 -337.205)"
  /></g><g transform="translate(8.931 45.48)"><path
    class="a"
    d="M79.93,359.028a1.8,1.8,0,0,0-2.537,0l-6.432,6.419a1.784,1.784,0,0,0,0,2.525,1.873,1.873,0,0,0,2.537,0l6.432-6.419A1.784,1.784,0,0,0,79.93,359.028Z"
    transform="translate(-70.438 -358.505)"
  /></g><g transform="translate(11.633 54.411)"><path
    class="a"
    d="M101.23,429.428a1.8,1.8,0,0,0-2.537,0l-6.432,6.419a1.784,1.784,0,0,0,0,2.524,1.873,1.873,0,0,0,2.537,0l6.432-6.419A1.784,1.784,0,0,0,101.23,429.428Z"
    transform="translate(-91.738 -428.905)"
  /></g><g transform="translate(31.401 20.517)"><path
    class="a"
    d="M253.83,161.73A6.267,6.267,0,1,0,260.1,168,6.272,6.272,0,0,0,253.83,161.73Zm-.013,8.944a2.689,2.689,0,1,1,2.689-2.689A2.691,2.691,0,0,1,253.817,170.674Z"
    transform="translate(-247.563 -161.73)"
  /></g></svg>
</template>

<script>
export default {
  name: 'RocketSignUp'
}
</script>

<style scoped>
.a {
  fill: #999999;
}
</style>
