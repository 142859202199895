<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="175"
    height="51"
    viewBox="0 0 175 51"
  ><g transform="translate(-380 -1561)"><g
    class="a"
    transform="translate(380 1561)"
  ><rect
    class="e"
    width="175"
    height="51"
    rx="25.5"
  /><rect
    class="f"
    x="1.5"
    y="1.5"
    width="172"
    height="48"
    rx="24"
  /></g><g transform="translate(399.094 1584.014)"><ellipse
    class="b"
    cx="2.427"
    cy="2.427"
    rx="2.427"
    ry="2.427"
    transform="translate(0 0)"
  /><ellipse
    class="b"
    cx="2.427"
    cy="2.427"
    rx="2.427"
    ry="2.427"
    transform="translate(9.072 0)"
  /><ellipse
    class="b"
    cx="2.427"
    cy="2.427"
    rx="2.427"
    ry="2.427"
    transform="translate(18.145 0)"
  /></g><g
    class="c"
    transform="translate(508 1570)"
  ><circle
    class="e"
    cx="16"
    cy="16"
    r="16"
  /><circle
    class="f"
    cx="16"
    cy="16"
    r="15"
  /></g><path
    class="d"
    d="M394.736,117.621a2.045,2.045,0,0,0-2.892,0l-5.455,5.455-2.161-2.161a2.045,2.045,0,0,0-2.892,2.891l3.607,3.607a2.045,2.045,0,0,0,2.892,0l6.9-6.9A2.045,2.045,0,0,0,394.736,117.621Z"
    transform="translate(135.559 1463.463)"
  /></g></svg>
</template>

<script>
export default {
  name: 'CheckAddress'
}
</script>

<style scoped>
.a,
.c {
  fill: #fff;
}
.a,
.c,
.d {
  stroke: #999999;
}
.a {
  stroke-miterlimit: 10;
  stroke-width: 3px;
}
.c {
  stroke-width: 2px;
}
.d {
  fill: #999999;
}
.e {
  stroke: none;
}
.f {
  fill: none;
}
</style>
