
import { Vue, Component } from 'vue-property-decorator'
import Tick from '~/components/Icons/Tick.vue'
import NoLockInIcon from '~/components/Icons/NoLockInIcon.vue'
import UnlimitedDataIcon from '~/components/Icons/UnlimitedDataIcon.vue'
import PriceTag from '~/components/Icons/PriceTag.vue'
import FreeActivationIcon from '~/components/Icons/FreeActivationIcon.vue'
import ServiceQualification from '~/components/ServiceQualification.vue'

@Component({
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand
    }
  },
  components: {
    Tick,
    NoLockInIcon,
    UnlimitedDataIcon,
    PriceTag,
    FreeActivationIcon,
    ServiceQualification
  },

  props: {
    discounts: Array,
    promoCode: String,
    carriers: Array,
    conditions: String,
    minCost: String
  }
})
export default class PromoPreview extends Vue {}
