<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="118.371"
    height="71.981"
    viewBox="0 0 118.371 71.981"
  ><g transform="translate(-716.701 -1561.019)"><g
    class="a"
    transform="translate(716.701 1561.467)"
  ><rect
    class="c"
    width="61.269"
    height="65.692"
    rx="3"
  /><rect
    class="d"
    x="1.5"
    y="1.5"
    width="58.269"
    height="62.692"
    rx="1.5"
  /></g><g transform="translate(736.184 1605.101)"><path
    class="b"
    d="M46.544,22.7H30.909c-2.158,0-3.909-.38-3.909-.848S28.751,21,30.909,21H46.544c2.158,0,3.909.38,3.909.848S48.7,22.7,46.544,22.7Z"
    transform="translate(-27 -21)"
  /><path
    class="b"
    d="M46.544,26.7H30.909c-2.158,0-3.909-.38-3.909-.848S28.751,25,30.909,25H46.544c2.158,0,3.909.38,3.909.848S48.7,26.7,46.544,26.7Z"
    transform="translate(-27 -21.606)"
  /><path
    class="b"
    d="M46.544,30.7H30.909c-2.158,0-3.909-.38-3.909-.848S28.751,29,30.909,29H46.544c2.158,0,3.909.38,3.909.848S48.7,30.7,46.544,30.7Z"
    transform="translate(-27 -22.213)"
  /></g><g transform="translate(734.672 1578.875)"><path
    class="b"
    d="M70.48,96.206A12.91,12.91,0,0,0,59.3,115.57l2.74-1.587a9.741,9.741,0,1,1,16.876,0l2.741,1.586A12.91,12.91,0,0,0,70.48,96.206Z"
    transform="translate(-57.572 -96.206)"
  /><path
    class="b"
    d="M104.483,132.943l5.425-7.664-7.664,5.425Z"
    transform="translate(-90.456 -117.607)"
  /></g><g transform="translate(774.367 1564.174) rotate(14)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path
    class="b"
    d="M.446.446A1.524,1.524,0,0,1,2.6.446L17.595,8.211a4.582,4.582,0,0,1,.861-.31L32.247,4.494a4.594,4.594,0,0,1,4.341,1.2l24.638,24.6a4.577,4.577,0,0,1,0,6.465L43.988,54a4.576,4.576,0,0,1-6.465,0l-24.6-24.638a4.594,4.594,0,0,1-1.2-4.341L15.13,11.227a4.593,4.593,0,0,1,.31-.861L.446,2.6A1.524,1.524,0,0,1,.446.446ZM14.679,25.758a1.531,1.531,0,0,0,.4,1.447l24.6,24.638a1.526,1.526,0,0,0,2.155,0L59.072,34.6a1.526,1.526,0,0,0,0-2.155L34.433,7.851a1.532,1.532,0,0,0-1.081-.446,1.511,1.511,0,0,0-.366.045l-12.954,3.2L23.7,14.318a4.571,4.571,0,1,1-2.155,2.155L17.877,12.8Zm9.914-6.238a1.523,1.523,0,1,0,0-2.155h0A1.525,1.525,0,0,0,24.594,19.52Z"
  /></g><g transform="translate(31.983 39.929) rotate(-101)"><path
    class="b"
    d="M.033,9.688a1.124,1.124,0,0,1,.2-.982,1.465,1.465,0,0,1,.931-.487L2.424,7.98a1.229,1.229,0,0,1,1.025.171,1.645,1.645,0,0,1,.512.922,8.414,8.414,0,0,0,.5,1.452,4.5,4.5,0,0,0,.956,1.4,2.948,2.948,0,0,0,.5.4,2.009,2.009,0,0,0,.581.256,1.185,1.185,0,0,0,.6,0,1.263,1.263,0,0,0,.572-.35,1.02,1.02,0,0,0,.188-1.008,11.889,11.889,0,0,0-.5-1.554q-.342-.888-.751-1.93a9.4,9.4,0,0,1-.564-2.118A5.925,5.925,0,0,1,6.121,3.5,4.014,4.014,0,0,1,7.274,1.525,5.051,5.051,0,0,1,8.837.44a4.819,4.819,0,0,1,1.75-.4,5.443,5.443,0,0,1,1.819.248,6.608,6.608,0,0,1,1.768.862L14.84.483A1.143,1.143,0,0,1,16.667.432l.1.1q.871.871.1,1.639l-.786.786A7.42,7.42,0,0,1,17.2,4.735a10.158,10.158,0,0,1,.512,1.486,1.12,1.12,0,0,1-.128.982,1.755,1.755,0,0,1-.862.589l-1.213.393a1.2,1.2,0,0,1-1.033-.043,1.523,1.523,0,0,1-.572-.914,6.682,6.682,0,0,0-.478-1.213,4.6,4.6,0,0,0-.871-1.2,2.62,2.62,0,0,0-1.1-.657,1.084,1.084,0,0,0-1.153.316,1.06,1.06,0,0,0-.214,1.033A8.606,8.606,0,0,0,10.6,7.05q.367.862.786,1.9a9.673,9.673,0,0,1,.589,2.092,5.535,5.535,0,0,1-.068,2.118,4.124,4.124,0,0,1-1.179,2A5.182,5.182,0,0,1,9.2,16.22a5.037,5.037,0,0,1-1.776.444,5.593,5.593,0,0,1-1.913-.222,5.978,5.978,0,0,1-1.921-.982l-.615.615a1.134,1.134,0,0,1-1.81.068l-.1-.1q-.888-.888-.12-1.657l.786-.786a7.993,7.993,0,0,1-1.2-2.135A12.023,12.023,0,0,1,.033,9.688Z"
    transform="translate(0 0)"
  /></g></g></g><circle
    class="b"
    cx="4.966"
    cy="4.966"
    r="4.966"
    transform="translate(772.746 1561.019) rotate(14)"
  /></g></svg>
</template>

<script>
export default {
  name: 'ChoosePlan'
}
</script>

<style scoped>
.a {
  fill: #fff;
  stroke: #999999;
  stroke-width: 3px;
}
.b {
  fill: #999999;
}
.c {
  stroke: none;
}
.d {
  fill: none;
}
</style>
