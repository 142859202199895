var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-3/4 m-auto mt-8"},[_c('h1',{staticClass:"pt-12 pb-4 font-5xl tracking-wide leading-normal font-semibold text-center uppercase"},[_vm._v("\n    Getting Connected is Easy!\n  ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col md:flex-row justify-between items-start content-container overflow-hidden"},[_c('div',{staticClass:"flex flex-col justify-between items-start w-full"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),(_vm.siteId !== 'qnet')?_c('div',{staticClass:"icon text-green px-4 py-4 w-3/5"},[_c('check-address',{staticClass:"w-4/5"})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex flex-col w-full"},[_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),(_vm.siteId !== 'qnet')?_c('div',{staticClass:"icon text-green px-4 py-4 w-3/5"},[_c('choose-plan',{staticClass:"w-4/5"})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex flex-col w-full"},[_vm._m(6),_vm._v(" "),_vm._m(7),_vm._v(" "),_vm._m(8),_vm._v(" "),(_vm.siteId !== 'qnet')?_c('div',{staticClass:"icon text-green px-4 py-4 w-3/5"},[_c('sign-up',{staticClass:"w-4/5"})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex flex-col w-full"},[_vm._m(9),_vm._v(" "),_vm._m(10),_vm._v(" "),_vm._m(11),_vm._v(" "),(_vm.siteId !== 'qnet')?_c('div',{staticClass:"icon text-green px-4 py-4 w-3/5"},[_c('rocket-sign-up',{staticClass:"w-4/5"})],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading h-10 px-4"},[_c('p',{staticClass:"font-bold text-xl"},[_vm._v("\n          1. Check Address\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline flex justify-start items-center w-full h-10 relative flex-none px-4"},[_c('div',{staticClass:"h-px w-full absolute top-0 left-0 bg-brand-colour-alt"}),_vm._v(" "),_c('div',{staticClass:"ball rounded-full bg-brand-colour-alt h-4 w-4"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info px-4 text-grey-dark flex-grow h-10"},[_c('p',[_vm._v("Enter your address and see which services are available at your place")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading h-10 px-4"},[_c('p',{staticClass:"font-bold text-xl"},[_vm._v("\n          2. Choose internet plan\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline flex justify-start items-center h-10 relative px-4"},[_c('div',{staticClass:"h-px w-full absolute top-0 left-0 bg-brand-colour-alt"}),_vm._v(" "),_c('div',{staticClass:"ball rounded-full bg-brand-colour-alt h-4 w-4"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info px-4 text-grey-dark flex-grow h-10"},[_c('p',[_vm._v("Pick your internet plan and get your special offer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading h-10 px-4"},[_c('p',{staticClass:"font-bold text-xl"},[_vm._v("\n          3. Sign up\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline flex justify-start items-center h-10 relative px-4"},[_c('div',{staticClass:"h-px w-full absolute top-0 left-0 bg-brand-colour-alt"}),_vm._v(" "),_c('div',{staticClass:"ball rounded-full bg-brand-colour-alt h-4 w-4"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info px-4 text-grey-dark flex-grow h-10"},[_c('p',[_vm._v("Signing up is easy, just a 2 step process")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading h-10 px-4"},[_c('p',{staticClass:"font-bold text-xl"},[_vm._v("\n          4. Get online\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline flex justify-start items-center h-10 relative px-4"},[_c('div',{staticClass:"h-px w-full absolute top-0 left-0 bg-brand-colour-alt"}),_vm._v(" "),_c('div',{staticClass:"ball rounded-full bg-brand-colour-alt h-4 w-4"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info px-4 text-grey-dark flex-grow h-10"},[_c('p',[_vm._v("Enjoy stress free Internet")])])
}]

export { render, staticRenderFns }