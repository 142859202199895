
import { Vue, Component } from 'vue-property-decorator'

@Component({
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand
    }
  }
})
export default class PlanPreviews extends Vue {}
