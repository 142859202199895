<template>
  <div class="w-3/4 m-auto mt-8">
    <h1 class="pt-12 pb-4 font-5xl tracking-wide leading-normal font-semibold text-center uppercase">
      Getting Connected is Easy!
    </h1>
    <div class="flex flex-col md:flex-row justify-between items-start content-container overflow-hidden">
      <div class="flex flex-col justify-between items-start w-full">
        <div class="heading h-10 px-4">
          <p class="font-bold text-xl">
            1. Check Address
          </p>
        </div>
        <div class="timeline flex justify-start items-center w-full h-10 relative flex-none px-4">
          <div class="h-px w-full absolute top-0 left-0 bg-brand-colour-alt" />
          <div class="ball rounded-full bg-brand-colour-alt h-4 w-4" />
        </div>
        <div class="info px-4 text-grey-dark flex-grow h-10">
          <p>Enter your address and see which services are available at your place</p>
        </div>
        <div 
          v-if="siteId !== 'qnet'"
          class="icon text-green px-4 py-4 w-3/5"
        >
          <check-address class="w-4/5" />
        </div>
      </div>
      <div class="flex flex-col w-full">
        <div class="heading h-10 px-4">
          <p class="font-bold text-xl">
            2. Choose internet plan
          </p>
        </div>
        <div class="timeline flex justify-start items-center h-10 relative px-4">
          <div class="h-px w-full absolute top-0 left-0 bg-brand-colour-alt" />
          <div class="ball rounded-full bg-brand-colour-alt h-4 w-4" />
        </div>
        <div class="info px-4 text-grey-dark flex-grow h-10">
          <p>Pick your internet plan and get your special offer</p>
        </div>
        <div
          v-if="siteId !== 'qnet'"
          class="icon text-green px-4 py-4 w-3/5"
        >
          <choose-plan class="w-4/5" />
        </div>
      </div>
      <div class="flex flex-col w-full">
        <div class="heading h-10 px-4">
          <p class="font-bold text-xl">
            3. Sign up
          </p>
        </div>
        <div class="timeline flex justify-start items-center h-10 relative px-4">
          <div class="h-px w-full absolute top-0 left-0 bg-brand-colour-alt" />
          <div class="ball rounded-full bg-brand-colour-alt h-4 w-4" />
        </div>
        <div class="info px-4 text-grey-dark flex-grow h-10">
          <p>Signing up is easy, just a 2 step process</p>
        </div>
        <div
          v-if="siteId !== 'qnet'"
          class="icon text-green px-4 py-4 w-3/5"
        >
          <sign-up class="w-4/5" />
        </div>
      </div>
      <div class="flex flex-col w-full">
        <div class="heading h-10 px-4">
          <p class="font-bold text-xl">
            4. Get online
          </p>
        </div>
        <div class="timeline flex justify-start items-center h-10 relative px-4">
          <div class="h-px w-full absolute top-0 left-0 bg-brand-colour-alt" />
          <div class="ball rounded-full bg-brand-colour-alt h-4 w-4" />
        </div>
        <div class="info px-4 text-grey-dark flex-grow h-10">
          <p>Enjoy stress free Internet</p>
        </div>
        <div
          v-if="siteId !== 'qnet'"
          class="icon text-green px-4 py-4 w-3/5"
        >
          <rocket-sign-up class="w-4/5" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CheckAddress from '~/components/Icons/CheckAddress.vue'
import ChoosePlan from '~/components/Icons/ChoosePlan.vue'
import SignUp from '~/components/Icons/SignUp.vue'
import RocketSignUp from '~/components/Icons/RocketSignUp.vue'

export default {
  components: {
    CheckAddress,
    ChoosePlan,
    SignUp,
    RocketSignUp
  },
  data() {
    return {
      siteId: process.env.siteId
    }
  }
}
</script>
